import React from 'react';

export default function HamburgerSvg({fill}) {

  return (
    <svg width="33" height="21" viewBox="0 0 33 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 2H33M0 10.8148H33M0 19H33" stroke={fill} stroke-width="3" />
    </svg> 
    )
 
}
