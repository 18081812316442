import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link } from "gatsby"
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: settings => settings.linkColor,
  },
  text: {
    fontStyle: 16,
  }
}));

export default function DesktopNavLink({ label, to, color}) {

  const settings = {
    linkColor: color,
  }

  const classes = useStyles(settings);
  return (
    <Typography className={classes.text} variant="body1">
      <Link className={classes.link} to={to}>
        {label}
      </Link>
    </Typography>
  );
}

/**
* Type definitions
*/
DesktopNavLink.propTypes = {
  to: PropTypes.string.isRequired,
};