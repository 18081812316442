import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import DesktopNavbar from './DesktopNavbar';
import MobileNavbar from './MobileNavbar';


export default function Navbar( {page} ) {

  let settings;
  switch(page) {
    case 'LANDING': 
      settings = {
        backgroundColor: 'transparent',
        linksColor: '#D48D48'
      }
      break;
    case 'CONTACT': 
      settings = {
        backgroundColor: 'transparent',
        linksColor: '#3A5280'
      }
      break;
    case 'ABOUT':
      settings = {
        backgroundColor: 'transparent',
        linksColor: '#3A5280'
      }
      break;
    case 'INSPIRATION':
      settings = {
        backgroundColor: 'rgba(255,255,255,0.6)',
        linksColor: '#192C4F'
      }
      break;
    case 'SERVICES':
      settings = {
        backgroundColor: '#192C4F',
        linksColor: '#D48D48'
      }
      break;
    default:
      settings = null
      break;
  }

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  return (
    <>
      {isMobile && 
        <MobileNavbar settings={settings}/>
      }
      {!isMobile && 
        <DesktopNavbar settings={settings} />
      }
    </>
  )
}