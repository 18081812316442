import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ title, description }) {
  const { site, image} = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "social_image.jpg" }) {
          publicURL
        }
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const lang = 'sv';
  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || site.siteMetadata.title;

  let metaTags = [
    {
      name: `viewport`,
      content: 'width=device-width, initial-scale=1'
    },
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },{
      name: `og:image`,
      content: `https://www.themaler.se${image.publicURL}`
    }
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={metaTitle}
      meta={metaTags}
    />
  )
}

export default SEO
