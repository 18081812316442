import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SmallLogoSvg from '../icons/smallLgoSvg';
import { Link } from 'gatsby';
import {motion} from 'framer-motion'
import HamburgerSvg from '../icons/hamburgerSvg';
import CloseMenuSvg from '../icons/closeMenuSvg';
import MobileNavLink from './MobileNavLink';

const useStyles = makeStyles(theme => ({
  navbar: {
    zIndex: 1,
    height: 90  ,
    width: '100vw',
    backgroundColor: settings => settings.backgroundColor,
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 70,
    paddingRight: 70,
  },
  logo: {
    flexGrow: 1,
  },
  menu: {
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: '#192C4F',
    zIndex: 100
  },
  icon: {
    '&:hover': {
      cursor: 'pointer',
    }
  },
  menuTopSection: {
    width: '100%',
    height: 90,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 70,
    paddingRight: 70,
  },
  links: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingTop: 32,
    paddingRight: 70,
  },
  link: {
    paddingBottom: 16,
  },  
}));


export default function MobileNavbar( {settings} ) {

  const classes = useStyles(settings);
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const iconsColor = '#D48D48'
  const linksColor = '#F3DCC6'

  const showMenu = () => {
    setMenuIsOpen(true)
  }
  
  const hideMenu = () => {
    setMenuIsOpen(false)
  }
  
  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "100%" },
  }

  return (
    <>
    <nav className={classes.navbar}>
      <div className={classes.logo}>
        <Link to="/">
          <SmallLogoSvg fill={iconsColor} />
        </Link>
      </div>
      <motion.div
        whileHover={{
          scale: 1.1
        }}
        whileTap={{ scale: 0.9 }}
        className={classes.icon} onClick={showMenu}
        >
        <HamburgerSvg fill={iconsColor} />
      </motion.div>
    </nav>
    <motion.div
      initial="closed"
      animate={menuIsOpen ? "open" : "closed"}
      variants={variants}
      className={classes.menu}>   
      <div className={classes.menuTopSection}>
        <div className={classes.logo}>
          <Link onClick={hideMenu} to="/">
            <SmallLogoSvg fill={iconsColor} />
          </Link>
        </div>
        <motion.div
          whileTap={{ scale: 0.9 }}
          className={classes.icon} onClick={hideMenu}
        >
          <CloseMenuSvg fill={iconsColor} />
        </motion.div>
      </div>
      <div className={classes.links}>
        <div className={classes.link}>
          <MobileNavLink onClick={hideMenu} label="inspiration" to="/inspiration/" color={linksColor} />
        </div>
        <div className={classes.link}>
          <MobileNavLink className={classes.link} onClick={hideMenu} label="tjänster" to="/tjanster/" color={linksColor} />
        </div>
        <div className={classes.link}>
          <MobileNavLink className={classes.link} onClick={hideMenu} label="om oss" to="/om-oss/" color={linksColor} />
        </div>
          <MobileNavLink onClick={hideMenu} label="kontakt" to="/kontakt/" color={linksColor} />
      </div>
    </motion.div>
    </>
  )
}