import React from 'react';

export default function CloseMenuSvg({fill}) {

  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 21.5L21 1.5" stroke={fill} stroke-width="2" stroke-linecap="round"/>
      <path d="M1 1.5L21 21.5" stroke={fill} stroke-width="2" stroke-linecap="round"/>
    </svg>
    )
 
}
