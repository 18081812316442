import React from 'react';

export default function InstagramLogo({fill}) {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M20.2397 0H8.67414C3.88354 0 0 3.88354 0 8.67414V20.2397C0 25.0302 3.88354 28.9138 8.67414 28.9138H20.2397C25.0303 28.9138 28.9138 25.0302 28.9138 20.2397V8.67414C28.9138 3.88354 25.0303 0 20.2397 0ZM2.89142 8.67398C2.89142 5.48026 5.48045 2.89123 8.67418 2.89123H20.2397C23.4334 2.89123 26.0225 5.48026 26.0225 8.67398V20.2395C26.0225 23.4332 23.4334 26.0223 20.2397 26.0223H8.67418C5.48045 26.0223 2.89142 23.4332 2.89142 20.2395V8.67398ZM14.4569 21.6853C10.4648 21.6853 7.22847 18.449 7.22847 14.4568C7.22847 10.4647 10.4648 7.22837 14.4569 7.22837C18.4491 7.22837 21.6854 10.4647 21.6854 14.4568C21.6854 18.449 18.4491 21.6853 14.4569 21.6853ZM14.4569 18.7938C16.8522 18.7938 18.794 16.852 18.794 14.4567C18.794 12.0614 16.8522 10.1197 14.4569 10.1197C12.0616 10.1197 10.1199 12.0614 10.1199 14.4567C10.1199 16.852 12.0616 18.7938 14.4569 18.7938ZM23.131 7.22848C23.131 8.02692 22.4838 8.67417 21.6853 8.67417C20.8869 8.67417 20.2396 8.02692 20.2396 7.22848C20.2396 6.43005 20.8869 5.78279 21.6853 5.78279C22.4838 5.78279 23.131 6.43005 23.131 7.22848Z" fill={fill} />
    </svg>

  );
}
