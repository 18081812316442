  import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SmallLogoSvg from '../icons/smallLgoSvg';
import DesktopNavLink from './DesktopNavLink';
import FacebookLogo from '../icons/facebookLogo';
import InstagramLogo from '../icons/instagramLogo';
import { Link } from 'gatsby';
import ExternalLink from '../links/ExternalLink';

const useStyles = makeStyles(theme => ({
  navbar: {
    zIndex: 1,
    height: 90  ,
    width: '100vw',
    backgroundColor: settings => settings.backgroundColor,
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 96,
    paddingRight: 96,
  },
  logo: {
    flexGrow: 1,
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  link: {
    marginRight: 32,
    color: settings => settings.linksColor,
  }
}));


export default function DesktopNavbar( {settings} ) {

  const classes = useStyles(settings);

  return (
  <nav className={classes.navbar}>
    <div className={classes.logo}>
      <Link aria-label="The Måler" alt="The Måler" to="/">
        <SmallLogoSvg fill={settings.linksColor} />
      </Link>
    </div>
    <div className={classes.linksContainer}>
      <div className={classes.link}>
        <DesktopNavLink color={settings.linksColor} label="inspiration" to="/inspiration/"/>
      </div>  
      <div className={classes.link}>
        <DesktopNavLink color={settings.linksColor} label="tjänster" to="/tjanster/"/>
      </div>           
      <div className={classes.link}>
        <DesktopNavLink color={settings.linksColor} label="om oss" to="/om-oss/"/>
      </div>
      <div className={classes.link}>
        <DesktopNavLink color={settings.linksColor} label="kontakt" to="/kontakt/"/>
      </div>
      <div className={classes.link}>
        <ExternalLink alt="The Måler Facebook" href="https://www.facebook.com/themaler/">
          <FacebookLogo fill={settings.linksColor} />
        </ExternalLink>
      </div>
      <ExternalLink alt="The Måler Instagram" href="https://www.instagram.com/the_maler_i_are/">
        <InstagramLogo fill={settings.linksColor}/>
      </ExternalLink>
    </div>
  </nav>
  )
}